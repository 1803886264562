//import { Tooltip, Toast, Popover } from 'bootstrap';

// Import only the Bootstrap components we need
import { Popover } from 'bootstrap';

import AOS from 'aos';

// Create an example popover
document.querySelectorAll('[data-bs-toggle="popover"]')
  .forEach(popover => {
    new Popover(popover)
  });

  AOS.init();

